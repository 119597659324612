import React from 'react';
import {Container, Row} from 'react-bootstrap';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/section';
import SectionHero from '../components/section-hero';
import CardAppModelSystems from '../components/card-app-model-systems';
import CardAppPeCan from '../components/card-app-pecan';
import CardAppPlatform from '../components/card-app-platform';
import CardAppVizComm from '../components/card-app-vizcomm';

const AppsPage = () => (
  <Layout>
    <SEO title="Apps on St. Jude Cloud" />
    <SectionHero fileName="apps-background.png">
      <Container className="apps__hero__container">
        <h1>Apps</h1>
      </Container>
    </SectionHero>
    <Section>
      <Row>
        <h2>Building Blocks for Discovery</h2>
        <p>
          Applications are the foundation of the St. Jude Cloud ecosystem. Teams at St. Jude
          independently develop apps to serve different areas of data sharing. When appropriate,
          their apps integrate with others on St. Jude Cloud to enable cross-functional experiences.
          Because of their design, our apps are a cohesive blend of comprehensive data, scientific
          expertise, engineering innovation, and cloud infrastructure creating a platform where
          discoveries can be made by researchers across the world. For an in-depth guide to our
          apps, view <a href="https://university.stjude.cloud/docs/#apps">our documentation</a>.
        </p>
      </Row>
    </Section>
    <Section className="apps__row__cards" gray>
      <Row>
        <CardAppPlatform lg={6} />
        <CardAppPeCan lg={6} />
        <CardAppVizComm lg={6} />
        <CardAppModelSystems lg={6} />
      </Row>
    </Section>
  </Layout>
);

export default AppsPage;
